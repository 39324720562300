<template>
  <div id="hub"></div>
</template>

<script>
import CrossStorage from "cross-storage";
let CrossStorageHub = CrossStorage.CrossStorageHub;
const oldUrlList = location.host.split(".");
const endUrl = oldUrlList[oldUrlList.length - 2] + "." + oldUrlList[oldUrlList.length - 1]; // 获取当前地址栏后缀主域名地址
let resq = new RegExp(`.*\\w+.${endUrl}.*$`)
CrossStorageHub.init([
  { origin: /localhost/, allow: ["get", "set"] },
  { origin: /.*192.168.31.*?$/, allow: ["get", "set"] },
  { origin: /.*172.18.24.*?$/, allow: ["get", "set"] },
  { origin: /.*\w+.sshlqf.com.*$/, allow: ["get", "set"] },
  { origin: resq, allow: ["get", "set"] }
])
export default {
  name: "Hub"
}
</script>

<style scoped></style>
